import { BalHeading, BalText } from "@baloise/ds-react";
import {
    DeepPartial,
    Form,
    FormProps,
    FormValues,
} from "@baloise-cfa/form-renderer-frontend";
import { WtcInfo, WtcReason } from "@baloise-cfa/tsclient/mob";
import { graphql, navigate } from "gatsby";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import { CompletedCard, Navigation } from "@modules/shared/components";
import { Application, LeadInfoKey, PageNames } from "@modules/shared/enums";
import {
    findNextInvalidTab,
    getNavigationPages,
    modelDTO,
    validateBusinessRules,
} from "@modules/shared/helpers";
import { useCreateLead, useWtc } from "@modules/shared/hooks";
import { AppState, appState } from "@modules/shared/state";
import { PageProps, TabsStatus } from "@modules/shared/types";
import {
    contactProfessionalFormConfig,
    SimulatorLayout,
    TabsLayout,
} from "@modules/simulator";

const ContactPage: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, ownPageObjects: allPages, language } = pageContext;
    const [appData, setAppData] = useRecoilState<AppState>(appState);
    const { getWtcPage, businessRules } = useWtc(allPages);
    const createLead = useCreateLead();
    const { t } = useTranslation();

    const { prevPage, nextPage } = getNavigationPages(allPages, page);

    const [formProps, setFormProps] = useState<
        Pick<FormProps<FormValues>, "dirty" | "isValid">
    >({
        isValid: false,
        dirty: false,
    });

    const initialFormValues: DeepPartial<FormValues> = useMemo(() => {
        return {
            ...appData,
            ContactProfessional: appData.ContactProfessional,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateFormState = (formValues: FormValues): void => {
        setAppData({
            ...appData,
            ContactProfessional: formValues.ContactProfessional,
        });
    };

    const handleSubmit = async (
        values: FormValues,
        tabsStatus: TabsStatus,
    ): Promise<void> => {
        updateFormState(values);
        const nextInvalidTab = findNextInvalidTab(
            tabsStatus,
            page,
            allPages,
            language,
        );
        const wtcPage = getWtcPage(
            validateBusinessRules(businessRules, appData).action,
        );

        const wtcInfo: WtcInfo = {
            wtcReason: [WtcReason.NoPriceAvailable],
        };
        if (wtcPage) {
            navigate(wtcPage.paths[language]);
            return;
        }
        if (nextInvalidTab) {
            navigate(nextInvalidTab.paths[language]);
            return;
        }
        const dto = modelDTO(
            appData,
            language,
            LeadInfoKey.WTCContactMe,
            wtcInfo,
            Application.Simulator,
        );
        const { success } = await createLead(dto, Application.Simulator);
        if (success && nextPage) {
            navigate(nextPage.paths[language]);
        }
    };

    const handleOnChange = (
        values: FormValues,
        props: Pick<FormProps<FormValues>, "dirty" | "isValid">,
    ): void => {
        setFormProps(props);
        updateFormState(values);
    };

    const renderHeaderComponent = useCallback(
        (tabsStatus: TabsStatus) => {
            const showCompleteCard = formProps.dirty
                ? formProps.isValid
                : tabsStatus && tabsStatus[page.name]?.isValid;
            const nextInvalidTab = findNextInvalidTab(
                tabsStatus,
                page,
                allPages,
                language,
            );
            if (showCompleteCard) {
                return (
                    <CompletedCard
                        nextPage={nextInvalidTab ?? nextPage}
                        currentPage={page}
                        language={language}
                    />
                );
            }
        },
        [
            formProps.dirty,
            formProps.isValid,
            page,
            allPages,
            language,
            nextPage,
        ],
    );

    return (
        <>
            <SimulatorLayout
                title={t("simulator.contactprofessional.title")}
                page={page}
                language={language}
                allPages={allPages}
            >
                <TabsLayout
                    activeTabName={page.name}
                    title={t("simulator.tabs.title")}
                    description={t("simulator.tabs.description")}
                    language={language}
                    allPages={allPages}
                >
                    {({ tabsStatus }) => {
                        return (
                            <>
                                <div className="intro">
                                    <BalHeading level="h1">
                                        {t("dealer.contact.title")}
                                    </BalHeading>
                                    <BalText>
                                        {t("dealer.contact.intro")}
                                    </BalText>
                                </div>
                                <Form
                                    scrollToFieldError
                                    formContext={{
                                        t,
                                        nameSpace:
                                            PageNames.ContactProfessional,
                                        fieldWrapper: {
                                            optionalLabel: "all.optional",
                                        },
                                    }}
                                    initialValues={initialFormValues}
                                    onSubmit={(values) =>
                                        handleSubmit(values, tabsStatus)
                                    }
                                    onChange={handleOnChange}
                                    fields={
                                        contactProfessionalFormConfig.fields
                                    }
                                    errorMessageCardTitle={
                                        t("all.errormessage.title") as string
                                    }
                                    errorMessageCardSubTitle={
                                        t("all.errormessage.text") as string
                                    }
                                    headerComponent={() =>
                                        renderHeaderComponent(tabsStatus)
                                    }
                                    enableReinitialize
                                >
                                    <Navigation
                                        t={t}
                                        language={language}
                                        prevPage={prevPage}
                                        nextPage={nextPage}
                                    />
                                </Form>
                            </>
                        );
                    }}
                </TabsLayout>
            </SimulatorLayout>
        </>
    );
};

export default ContactPage;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
